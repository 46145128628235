<template>
	<div class="my-account va-row">
		<div class="header-menu va-row">
			<div class="container-dhh va-row">
				<div class="close">
					<router-link :to="{ name: 'next-kits' }">
						<!-- <i class="fa fa-chevron-left"></i> -->
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="36"
							height="36"
							viewBox="0 0 36 36"
						>
							<path
								fill="#000"
								fill-rule="evenodd"
								stroke="#000"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="1.6"
								d="M15.123 11.294a1.004 1.004 0 0 1 0 1.42l-4.282 4.282h17.156a1.003 1.003 0 1 1 0 2.007H10.84l4.282 4.284a1.004 1.004 0 0 1-1.419 1.42L7 18l6.705-6.706a1.003 1.003 0 0 1 1.418 0z"
							/>
						</svg>
					</router-link>
				</div>

				<div class="flex xs12 no-padding text-center">
					<h3>Editar nome do protagonista</h3>
				</div>

				<!-- <hr> -->
			</div>

			<br />
			<div class="clearfix"></div>
		</div>

		<div class="container-dhh va-row next-kits">
			<div class="flex xs12">
				<div class="va-row no-padding">
					<div class="flex md4 offset--md4 xs12 text-right">
						<input-normal
							type="text"
							label="Nome"
							id="name"
							name="name"
							:disabled="false"
							maxlength="20"
							:validate="'required'"
							:value="subscription.subscription.characterName"
							@input="
								subscription.subscription.characterName = $event.target.value
							"
						/>
						<button
							class="btn btn-primary"
							type="button"
							@click="showSmallModal()"
						>
							SALVAR
						</button>

						<!-- {{susbcription}} -->
					</div>
				</div>
			</div>
		</div>

		<!-- {{susbcription}} -->
		<name-modal
			:show.sync="show"
			ref="smallModal"
			v-bind:small="true"
			:cancelClass="'btn btn-primary btn-cancel'"
			:cancelDisabled="false"
			:okText="'FECHAR'"
			:noButtons="false"
			:subscriptionId="subscription.subscription.id"
			:subscriptionStackId="subscription.subscription.subscriptionStackId"
			:bookName="subscription.product.name"
			:bookImage="subscription.product.builderCharacterId"
			:characterName="subscription.subscription.characterName"
			:BuilderCharacterId="subscription.product.builderCharacterId"
			:BuilderProductId="subscription.product.builderProductId"
			:cancelText="'CANCELA'"
		>
		</name-modal>

		<!-- {{subscription}} -->
	</div>
</template>

<script>
	// import { required, minLength, between } from 'vuelidate/lib/validators'
	import { mask } from "vue-the-mask";
	const axios = require("axios");

	export default {
		name: "my-account",
		directives: { mask },
		components: {
			// vuescroll
			// VueScrollTo
			// DataVisualisationTab,
		},

		props: {
			subscription: {
				type: Object,
				default: null,
			},
		},

		mounted() {
			document.getElementsByClassName("ddh-sidebar")[0].style.display = "none";
			document.getElementsByClassName("ddh-header")[0].style.display = "none";
		},
		beforeDestroy() {
			document.getElementsByClassName("ddh-sidebar")[0].style.display = "block";
			document.getElementsByClassName("ddh-header")[0].style.display = "block";
		},
		data() {
			return {
				subscriptionInfo: null,
			};
		},
		methods: {
			showSmallModal() {
				this.$refs.smallModal.open();
			},
		},
	};
</script>
<style lang="scss" scoped>
	.ddh-sidebar,
	.ddh-sidebar.hide-sidebar,
	.ddh-header {
		display: none !important;
	}
	.close {
		a {
			position: absolute;
			top: 26px;
			color: #4965bc;
			opacity: 1;

			// svg{
			//     path{
			//       fill:#4965bc;
			//       stroke:#4965bc;
			//     }
			//   }

			&:hover {
				svg {
					path {
						fill: #4965bc;
						stroke: #4965bc;
					}
				}
			}

			@media (max-width: 990px) {
				top: 7px;
				left: 15px;
			}
		}
	}

	::v-deep .floating-input {
		background-color: white !important;
	}
	.floating-input {
		background-color: white !important;
	}
	.change-visualization {
		text-align: right;
		font-family: "Roboto";
		font-size: 14px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
		padding-top: 46px !important;

		span {
			margin-left: 5px;
			&.active {
				svg {
					path {
						fill: #4965bc;
					}
				}
			}
		}
	}
	.filters {
		margin-bottom: 30px !important;
		.character.active {
			border: solid 2px #4965bc;
		}
	}
	.see-all {
		border-radius: 50px;
		border: solid 1px #dfdae4;
		background-color: #fff;
		font-family: Roboto;
		font-size: 18px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: normal;
		text-align: center;
		color: #dfdae4;
		line-height: 80px;
		padding: 0px 30px;
		display: inline-block;

		&.active {
			border: solid 2px #4965bc;
			color: #4965bc;
			background-color: #edffff;
		}
	}
	.character {
		border-radius: 50px;
		border: solid 1px #dfdae4;
		background-color: #ffffff;
		display: inline-block;
		padding: 10px 13px;
		overflow: hidden;
		margin-left: 10px;
	}

	.dropdown-name {
		position: relative;
		float: left;

		.fa {
			border-radius: 25px;
			border: solid 1px #dfdae4;
			background-color: #ffffff;
			font-size: 20px;
			width: 40px;
			height: 40px;
			text-align: center;
			float: right;
			line-height: 38px;
			margin-top: -7px;
			display: inline-block;
			margin-left: 10px;
			color: #1ad3d9;
		}

		&:hover {
			.menu-dropdown {
				display: block;
			}
		}
	}
	.menu-dropdown {
		display: none;
		position: absolute;
		top: 90px;
		border-top: 10px solid #907feb;
		border-radius: 10px;
		width: 100%;
		border-radius: 10px;
		-webkit-box-shadow: 0 2px 4px 0 rgba(175, 162, 186, 0.4);
		box-shadow: 0 2px 4px 0 rgba(175, 162, 186, 0.4);
		background-color: #ffffff;
		padding: 20px;
		padding-top: 0px;
		right: 0;
		z-index: 4;

		a {
			font-family: Nunito;
			font-size: 14px;
			font-weight: 600;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #68596e;
			border-bottom: 1px solid #dfdae4;
			width: 100%;
			display: block;
			height: 57px;
			position: relative;
			line-height: 60px;
			display: block;
			width: 100%;

			&:hover {
				color: #000;
			}

			&:last-child {
				border-bottom: 0px;
			}

			&:before {
				content: "\F054";
				color: #907feb;
				position: absolute;
				top: 24px;
				width: 4px;
				height: 4px;
				font: normal normal normal 14px/1 FontAwesome;
				right: 9px;
				margin-left: auto;
				margin-right: auto;
			}
		}

		&:before {
			content: "\F0D8";
			color: #907feb;
			position: absolute;
			top: -20px;
			width: 4px;
			height: 4px;
			font: normal normal normal 14px/1 FontAwesome;
			right: 0;
			left: 0;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.next-kits {
		h1 {
			font-family: "Nunito";
			font-size: 20px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.4;
			letter-spacing: 1.1px;
			color: #000000;
			margin-top: 40px;
			margin-bottom: 5px;
		}
	}
	.panel-book {
		border-radius: 10px;
		border: solid 1px #dfdae4;
		background-color: #ffffff;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.38;
		letter-spacing: normal;
		text-align: center;
		color: #000000;
		padding: 23px 20px;

		&.grid {
			b {
				font-family: "Nunito";
				font-size: 24px;
				font-weight: 900;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				color: #000000;
			}

			p {
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
				font-family: "Nunito";
				font-size: 24px;
				font-weight: 900;
				margin-bottom: 5px;
			}

			.btn {
				border: none;
			}
		}

		img {
			margin: 0 auto;
			display: block;
			-webkit-box-shadow: -5px 5px 10px 0 rgba(0, 0, 0, 0.15),
				0 2px 50px 0 rgba(0, 0, 0, 0.15);
			box-shadow: -5px 5px 10px 0 rgba(0, 0, 0, 0.15),
				0 2px 50px 0 rgba(0, 0, 0, 0.15);
			width: 100%;
			max-width: 180px;
		}

		.text-description {
			display: block;
			width: 100%;
			text-align: center;
			font-family: Roboto;
			font-size: 16px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.31;
			letter-spacing: normal;
			color: #000000;
		}

		b {
			font-weight: 900;
			color: #000;
		}

		p {
			color: #000000;
			margin-bottom: 0px;
		}

		button {
			border-radius: 40px;
			border: solid 1px #dfdae4;
			background-image: none;
			background: #fff;
			color: #3bd3d6;
			position: relative;
			padding-right: 35px;

			i {
				position: absolute;
				right: 16px;
				top: 14px;
			}
		}

		.status-box {
			margin-bottom: 20px;
			border-radius: 5px;
			border: dashed 2px #9378f0;
			background-color: #ffffff;
			height: 50px;
			display: block;
			line-height: 49px;

			&.right {
				border-color: #00cf71;

				.ico-right {
					width: 37px;
					height: 37px;
					border-radius: 25px;
					background-color: #00cf71;
					float: left;
					position: relative;
					left: 9px;
					top: 5px;
					line-height: 0px;

					svg {
						path {
							fill: white;
						}
					}
				}
			}

			.ico-pending {
				width: 37px;
				height: 37px;
				border-radius: 25px;
				background-color: #9378f0;
				float: left;
				position: relative;
				line-height: 0px;
				display: inline-block;
				margin-top: 5px;
				margin-left: 9px;
				margin-right: 11px;

				svg {
					path {
						fill: white;
					}
				}
			}
		}

		// States
	}

	.header-purple {
		height: 87px;
		border-radius: 10px 10px 0px 0px;
		border: solid 1px #9378f0;
		background-color: #9378f0;
		padding: 20px 16px;

		h1 {
			font-family: Roboto;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
			margin: 0px;
			line-height: 47px;
		}

		.box-date {
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 2.5;
			letter-spacing: normal;
			color: #ffffff;
			height: 40px;
			border-radius: 40px;
			background-color: #7659dc;
			text-align: center;
			display: inline-block;
			padding: 2px 29px;
			margin-top: 3px;
		}
	}
	.learn-paths {
		h1 {
			margin-top: 0px;
			font-family: Nunito;
			font-size: 24px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #000000;
			margin-bottom: 8px;
		}

		.box-home {
			border-radius: 5px;
			border: dashed 2px #9378f0;
			background-color: #ffffff;
			padding: 10px 17px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #000000;

			.house-icon {
				float: left;
				width: 50px;
				height: 50px;
				border-radius: 25px;
				background-color: #9378f0;
				text-align: center;
				padding-top: 6px;
				margin-right: 10px;
				svg {
					path {
						fill: #fff;
					}
				}
			}

			p {
				padding-top: 4px;
				margin-bottom: 0px;
			}
		}
	}

	.line-item {
		width: 100%;

		&.active {
			.line {
				&:before {
					content: "";
					position: absolute;
					left: -10px;
					top: 30px;
					width: 20px;
					height: 20px;
					border-radius: 25px;
					background-color: #9378f0;
				}
			}
		}

		.line {
			width: 2px;
			height: 100%;
			display: block;
			background-color: #9378f0;
			float: left;
			margin-right: 30px;
			position: relative;
		}

		.item-line {
			width: 100%;
		}
	}

	.panel-kit {
		border-radius: 10px;
		border: solid 1px #dfdae4;
		background-color: #ffffff;

		.title {
			border-radius: 10px 10px 0px 0px;
			background-color: #f8f7fc;
			padding: 0px 20px;

			h1 {
				font-family: Roboto;
				font-size: 16px;
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
				letter-spacing: normal;
				color: #000000;
				line-height: 40px;
			}
		}
	}

	.book-main-info {
		.btn-change {
			height: 47px;
			font-family: Nunito;
			font-size: 12px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 5.5;
			letter-spacing: 0.7px;
			text-align: right;
			color: #4965bc;
			position: absolute;
			right: 0px;
			top: 10px;
		}
	}

	.name-son {
		position: relative;

		span {
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #ada1b9;
		}
		h1 {
			font-family: Nunito;
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: 2.2px;
			color: #000000;
			margin-top: 10px;
			margin-bottom: 0px;
		}
	}

	h3.next-kit {
		font-family: Nunito;
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}
	.next-kits {
		.btn.btn-change {
			font-family: Nunito;
			font-size: 12px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 0.7px;
			text-align: center;
			color: #4965bc;
			background: none;
			border: 0px;
			margin-top: 7px;
		}
	}
	.cover-photo {
		border-radius: 10px;
		overflow: hidden;

		img {
			max-width: 208px;
			float: left;
			width: 100%;
		}
		&.character {
			img {
				border-radius: 10px;
				border: 1px solid #ccc;
				max-width: 186px;
				float: left;
			}
		}
	}

	.product-info {
		h3 {
			font-family: Nunito;
			font-size: 24px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
		}
		p {
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.36;
			letter-spacing: normal;
			color: #000000;
		}
	}

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}
	.header-menu {
		background-color: white;
		width: 100%;
		min-height: 90px;

		@media (max-width: 990px) {
			width: 100%;
			min-height: 50px;
		}

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}

		h3 {
			font-family: "Nunito";
			font-size: 24px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 37px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 20px;
				margin-top: 20px;
				margin-bottom: 0px;
				padding-left: 35px;
			}
		}

		hr {
			border: 1px solid #dfdae4;
			width: 100%;
		}
	}
	.my-data {
		/* Facebook bar */
		.facebook-tag {
			height: 65px;
			border-radius: 5px;
			background-color: #3a5998;
			color: white;
			padding: 14px 20px;
			margin-bottom: 20px;

			svg {
				font-size: 26px;
				margin-right: 0px;
				float: left;

				path {
					fill: #fff;
				}
			}

			.name {
				display: inline-block;
				margin-top: 1px;
				line-height: 37px;
			}

			.facebook-toggle {
				float: right;
				display: block;
				margin-top: 5px;
			}
		}
	}

	.change-password {
		border-radius: 10px;
		border: solid 2px #f0ebf5;
		background-color: #ffffff;
		padding: 24px 20px;
		position: relative;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 7px;
			font-family: "Roboto";

			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
		}

		h5 {
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #afa2ba;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.floated-icon {
			float: left;
			width: 36px;
			height: 36px;
			font-size: 30px;

			svg {
				position: relative;
				top: -5px;
				left: -3px;

				path {
					fill: #afa2ba;
				}
			}
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 54%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.my-data-table {
		h4 {
			margin-top: 50px;
			margin-bottom: 20px;
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}
</style>
